@use '@angular/material' as mat;

@include mat.core();

$dark-primary-text : mat.define-palette(mat.$gray-palette, 900);
$light-primary-text : mat.define-palette(mat.$gray-palette, 50);

$palette: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #000000,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$convert : mat.define-palette($palette);

$secondary : #C715D3;
$light : mat.define-light-theme(
    (
        color : (
            primary: mat.define-palette($convert, 900),
            accent : mat.define-palette(mat.$purple-palette, A700),
            light-primary-text : mat.define-palette(mat.$grey-palette, 900),
            background : mat.define-palette($palette, 900)
        )
    )
);

$dark : mat.define-dark-theme(
    (

        color : (
            primary : mat.define-palette($convert, 900),
            accent : mat.define-palette(mat.$pink-palette, 300),
            light-primary-text : mat.define-palette(mat.$grey-palette, 50),
            basic : mat.define-palette($convert, 900)
        )
    )
);
@include mat.all-component-themes($light);

.dark-mode {
    @include mat.all-component-themes($dark);

    .bg-dark-input {
        background-color: #343a40 !important;
    }

    /* MAT SELECT  */

    .mat-mdc-select {
        background-color: #343a40;
        color: white;
    }

    .mat-paginator-container-background-color {
        background-color: #343a40;
    }
    
    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text{
        color: white;
    }

    .mat-mdc-select-panel {
        background-color: #343a40;
    }

    .mat-paginator-enabled-icon-color {
        background-color: transparent;
    }

    .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
        color: transparent;
    }

    /* END MAT SELECT */

    /* DATE PICKER */

    .mat-calendar {
        background-color: #343a40;
        color: white;
    }

    .mat-datepicker {
        background-color: #343a40;
    }

    .mat-mdc-form-field-focus-overlay {
        background: none;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background: #343a40;
    }

    .mat-calendar-body-selected {
        background-color: #D4AEF6;
        color: white;
    }
    /* END DATEPICKER */

    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
        border-bottom-color: transparent !important;
        visibility:hidden;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
        border-bottom-color: transparent !important;
        visibility:hidden;
    }

    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
        padding: 0px;
    }

    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
        color: white !important;
    }

    .fc-col-header-cell-cushion {
        color: white;
        text-decoration: none;
    }

    .fc-daygrid-day-number{
        color: white;
        text-decoration: none;
    }

    .fc-col-header-cell-cushion:hover {
        color: white;
        text-decoration: none;
    }

    .fc-daygrid-day-number:hover {
        color: white;
        text-decoration: none;
    }
    
    .fc-button-primary {
        background-color: rgb(167, 93, 232);
    }

    .fc-button-primary:hover {
        background-color: rgb(167, 93, 232);
    }

    .fc .fc-button-primary:disabled {
        background-color: rgb(167, 93, 232);
    }
    
    .table  {
        --bs-table-color: white; 
        --bs-table-bg: transparent; 
        --bs-table-border-color: black; 
        --bs-table-accent-bg: #212529; 
        --bs-table-striped-color: #212529; 
        --bs-table-striped-bg: white; 
        --bs-table-active-color: white; 
        --bs-table-active-bg: rgba(0, 0, 0, 0.1); 
        --bs-table-hover-color: white; 
        --bs-table-hover-bg:#333941; 
        width: 100%; 
        margin-bottom: 1rem; 
        color: var(--bs-table-color); 
        vertical-align: top; 
        border-color: var(--bs-table-border-color); 
    }

    .fc-daygrid-day-number {
        color: white !important;
    }
    
}

.mat-mdc-form-field-subscript-wrapper {
    display: none !important;
}

.mat-mdc-form-field-infix {
    min-height: auto !important;
}

.mat-datepicker-content {
    margin-top: 3%;
}

.fc-col-header-cell-cushion {
    color: #212529;
    text-decoration: none;
}

.fc-daygrid-day-number {
    color: #212529;
    text-decoration: none;
}

.fc-col-header-cell-cushion:hover {
    color: #212529;
    text-decoration: none;
}

.fc .fc-daygrid-day-number:hover {
    color: #212529;
    text-decoration: none;
}

.fc .fc-button-primary:disabled {
    background-color: rgb(167, 93, 232);
}

.fc .fc-button-primary {
    background-color: rgb(167, 93, 232);
}

.fc .fc-button-primary:hover {
    background-color: rgb(167, 93, 232);
}

.table  {
    --bs-table-color: white; 
    --bs-table-bg: transparent; 
    --bs-table-border-color: black; 
    --bs-table-accent-bg: #212529; 
    --bs-table-striped-color: #212529; 
    --bs-table-striped-bg: white; 
    --bs-table-active-color: white; 
    --bs-table-active-bg: rgba(0, 0, 0, 0.1); 
    --bs-table-hover-color: #808080; 
    --bs-table-hover-bg:white; 
    width: 100%; 
    margin-bottom: 1rem; 
    color: var(--bs-table-color); 
    vertical-align: top; 
    border-color: var(--bs-table-border-color); 
}

.fc-daygrid-day-number {
    color: #000000 !important;
}



.mat-mdc-select {
    background-color: white;
    color: #343a40;
}

.mat-paginator-enabled-icon-color {
    background-color: transparent;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
    color: transparent;
}


.mat-paginator-container-background-color {
    background-color: #343a40;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: #343a40 !important;
}

.mat-mdc-select-panel {
    background-color: white;
    color: #9E9E9E;
}

.mat-paginator-enabled-icon-color {
    background-color: transparent;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
    color: transparent;
}

/* END MAT SELECT */

/* DATE PICKER */

.mat-calendar {
    color: #343a40;
}

.mat-mdc-form-field-focus-overlay {
    background: none;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: #343a40;
}

.mat-calendar-body-selected {
    background-color: #D4AEF6;
    color: white;
}
/* END DATEPICKER */

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
    border-bottom-color: transparent !important;
    visibility:hidden;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: transparent !important;
    visibility:hidden;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding: 0px;
}

.fc-col-header-cell-cushion {
    color: white;
    text-decoration: none;
}

.fc-daygrid-day-number{
    color: white;
    text-decoration: none;
}

.fc-col-header-cell-cushion:hover {
    color: white;
    text-decoration: none;
}

.fc-daygrid-day-number:hover {
    color: white;
    text-decoration: none;
}