@font-face {
  font-family: Maven;
  src: url(assets/Font/maven/mavenpro-regular-webfont.woff) format("opentype");
}

html {
  background-color: black;
}

body {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 6px ;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: #D4AEF6;
  border-radius: 5px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center
}

.white-text {
    color: white !important;
}

.red-text {
  color: #B73436 !important;
}

.title-secondary {
  color: #808080;
}

.font-secondary {
  color: #9E9E9E;
}

.font-secondary-dark {
  color: #f0f0f0;
}

.pointer {
  cursor: pointer;
}

.clear-outline {
  border: none;
  outline: none;
  color: inherit;
  text-decoration: none;
  background: none;
  border-radius: 10px;
}

.clear-card-outline {
  border: none;
  outline: none;
  color: inherit;
  text-decoration: none;
  border-radius: 10px;
}

.rounded-corners {
  border-radius: 10px;
}

.clear-padding {
  padding: 0px;
}

.ngx-charts .gridline-path {
  stroke: #808080;
  opacity: 0.1;
}

.no-margin {
  margin: 0px;
}

.no-padding {
  padding: 0px;
}

.bg-primary-light {
  background-color: #f1f1f1;
}

.bg-primary-dark {
  background-color: #0e0e0e;
}

.bg-secondary-dark {
  background-color: #343a3f;
}

.bg-main-pink {
  background-color: #D13594;
}

.bg-main-green {
  background-color: #0EB594;
}

.bg-main-blue {
  background-color: #009CDE;
}

.bg-main-purple, .bg-main-purple:active, .bg-main-purple:focus  {
  background-color: #A75DE8;
}

.bg-white {
  background-color: white;
}

.rounded {
  border-radius: 20px !important;
  border: none;
}

.card-shadow {
  box-shadow: 0px 0px 10px #d3d3d3;
  margin-top: 10px;
}

.strike {
  text-decoration: line-through;
}

.render-rtl {
  direction: rtl;
}

.modal-header {
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
}

.input-border-light {
  border-color: #9E9E9EB3;
  border-width: 1px;
  border-radius: 10px;
}

.no-shadow {
  box-shadow: none !important;
}

.input-border-light.solid {
  border-style: solid;
}


.bg-gray, .bg-gray::placeholder {
  color: #8d8d8d;
  background-color: #575f68;
}

.bg-secondary-light {
  background-color: lightgray;
}

.bg-none, .bg-none:active, .bg-none:focus {
  background: none;
  background-color: transparent;
}

.no-focus:active, .no-focus:focus {
  background-color: transparent;
  background: none;
}

/* Colores */

.filter-white {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(309deg) brightness(104%) contrast(101%);
}

/* rojos */

.filter-red-1{
  filter: invert(15%) sepia(95%) saturate(3045%) hue-rotate(350deg) brightness(89%) contrast(95%);
}

.filter-red-2{
  filter: invert(15%) sepia(59%) saturate(4256%) hue-rotate(349deg) brightness(113%) contrast(87%);
}

.filter-red-3{
  filter: invert(19%) sepia(70%) saturate(2629%) hue-rotate(344deg) brightness(117%) contrast(90%);
}

.filter-red-4{
  filter: invert(29%) sepia(90%) saturate(4360%) hue-rotate(348deg) brightness(99%) contrast(82%);
}

.filter-red-5{
  filter: invert(37%) sepia(100%) saturate(3300%) hue-rotate(342deg) brightness(99%) contrast(94%);
}

.filter-red-6{
  filter: invert(33%) sepia(27%) saturate(3310%) hue-rotate(330deg) brightness(119%) contrast(87%);
}

.filter-red-7{
  filter: invert(75%) sepia(46%) saturate(3721%) hue-rotate(310deg) brightness(91%) contrast(97%);
}

.filter-red-8{
  filter: invert(64%) sepia(17%) saturate(829%) hue-rotate(314deg) brightness(106%) contrast(89%);
}

/* rosa */

.filter-pink-1{
  filter: invert(13%) sepia(71%) saturate(3194%) hue-rotate(310deg) brightness(93%) contrast(102%);
}

.filter-pink-2{
  filter: invert(15%) sepia(77%) saturate(3514%) hue-rotate(319deg) brightness(92%) contrast(97%);
}

.filter-pink-3{
  filter: invert(20%) sepia(72%) saturate(2790%) hue-rotate(317deg) brightness(88%) contrast(103%);
}

.filter-pink-4{
  filter: invert(23%) sepia(80%) saturate(3306%) hue-rotate(324deg) brightness(85%) contrast(99%);
}

.filter-pink-5{
  filter: invert(32%) sepia(83%) saturate(5227%) hue-rotate(325deg) brightness(91%) contrast(101%);
}

.filter-pink-6{
  filter: invert(41%) sepia(89%) saturate(2407%) hue-rotate(314deg) brightness(92%) contrast(100%);
}

.filter-pink-7{
  filter: invert(55%) sepia(85%) saturate(2183%) hue-rotate(305deg) brightness(99%) contrast(90%);
}

.filter-pink-8{
  filter: invert(68%) sepia(5%) saturate(4150%) hue-rotate(293deg) brightness(101%) contrast(91%);
}

/* purpura */

.filter-purple-1{
  filter: invert(12%) sepia(64%) saturate(4141%) hue-rotate(262deg) brightness(93%) contrast(106%);
}

.filter-purple-2{
  filter: invert(14%) sepia(63%) saturate(4356%) hue-rotate(271deg) brightness(87%) contrast(98%);
}

.filter-purple-3{
  filter: invert(18%) sepia(27%) saturate(7490%) hue-rotate(268deg) brightness(86%) contrast(103%);
}

.filter-purple-4{
  filter: invert(13%) sepia(79%) saturate(5020%) hue-rotate(284deg) brightness(87%) contrast(83%);
}

.filter-purple-5{
  filter: invert(20%) sepia(35%) saturate(6010%) hue-rotate(279deg) brightness(97%) contrast(93%);
}

.filter-purple-6{
  filter: invert(46%) sepia(25%) saturate(2826%) hue-rotate(256deg) brightness(76%) contrast(92%);
}

.filter-purple-7{
  filter: invert(48%) sepia(46%) saturate(646%) hue-rotate(245deg) brightness(95%) contrast(85%);
}

.filter-purple-8{
  filter: invert(62%) sepia(19%) saturate(781%) hue-rotate(245deg) brightness(103%) contrast(88%);
}

/* purpura obscuro */

.filter-dark-purple-1{
  filter: invert(10%) sepia(97%) saturate(3733%) hue-rotate(251deg) brightness(85%) contrast(101%);
}

.filter-dark-purple-2{
  filter: invert(22%) sepia(89%) saturate(1271%) hue-rotate(234deg) brightness(85%) contrast(116%);
}

.filter-dark-purple-3{
  filter: invert(10%) sepia(97%) saturate(4029%) hue-rotate(258deg) brightness(99%) contrast(84%);
}

.filter-dark-purple-4{
  filter: invert(22%) sepia(45%) saturate(3894%) hue-rotate(249deg) brightness(86%) contrast(91%);
}

.filter-dark-purple-5{
  filter: invert(26%) sepia(17%) saturate(7402%) hue-rotate(245deg) brightness(91%) contrast(94%);
}

.filter-dark-purple-6{
  filter: invert(42%) sepia(14%) saturate(3193%) hue-rotate(223deg) brightness(88%) contrast(84%);
}

.filter-dark-purple-7{
  filter: invert(61%) sepia(9%) saturate(7202%) hue-rotate(217deg) brightness(86%) contrast(86%);
}

.filter-dark-purple-8{
  filter: invert(65%) sepia(17%) saturate(759%) hue-rotate(220deg) brightness(102%) contrast(80%);
}

/* azul obscuro */

.filter-dark-blue-1{
  filter: invert(13%) sepia(69%) saturate(2271%) hue-rotate(225deg) brightness(102%) contrast(109%);
}

.filter-dark-blue-2{
  filter: invert(10%) sepia(56%) saturate(7479%) hue-rotate(238deg) brightness(86%) contrast(81%);
}

.filter-dark-blue-3{
  filter: invert(19%) sepia(41%) saturate(4664%) hue-rotate(227deg) brightness(83%) contrast(85%);
}

.filter-dark-blue-4{
  filter: invert(23%) sepia(67%) saturate(1845%) hue-rotate(217deg) brightness(91%) contrast(90%);
}

.filter-dark-blue-5{
  filter: invert(24%) sepia(91%) saturate(1506%) hue-rotate(218deg) brightness(91%) contrast(83%);
}

.filter-dark-blue-6{
  filter: invert(44%) sepia(41%) saturate(760%) hue-rotate(194deg) brightness(88%) contrast(88%);
}

.filter-dark-blue-7{
  filter: invert(64%) sepia(10%) saturate(2024%) hue-rotate(194deg) brightness(82%) contrast(92%);
}

.filter-dark-blue-8{
  filter: invert(74%) sepia(22%) saturate(731%) hue-rotate(196deg) brightness(90%) contrast(88%);
}

/* azul */

.filter-blue-1{
  filter: invert(15%) sepia(93%) saturate(2955%) hue-rotate(213deg) brightness(87%) contrast(90%);
}

.filter-blue-2{
  filter: invert(27%) sepia(99%) saturate(1238%) hue-rotate(193deg) brightness(94%) contrast(94%);
}

.filter-blue-3{
  filter: invert(31%) sepia(59%) saturate(1988%) hue-rotate(191deg) brightness(101%) contrast(87%);
}

.filter-blue-4{
  filter: invert(40%) sepia(94%) saturate(871%) hue-rotate(181deg) brightness(92%) contrast(95%);
}

.filter-blue-5{
  filter: invert(42%) sepia(86%) saturate(1146%) hue-rotate(184deg) brightness(98%) contrast(94%);
}

.filter-blue-6{
  filter: invert(60%) sepia(88%) saturate(1637%) hue-rotate(180deg) brightness(93%) contrast(106%);
}

.filter-blue-7{
  filter: invert(64%) sepia(37%) saturate(866%) hue-rotate(177deg) brightness(99%) contrast(95%);
}

.filter-blue-8{
  filter: invert(64%) sepia(100%) saturate(159%) hue-rotate(167deg) brightness(98%) contrast(100%);
}

/* cyan */

.filter-cyan-1{
  filter: invert(27%) sepia(14%) saturate(4316%) hue-rotate(143deg) brightness(99%) contrast(103%);
}

.filter-cyan-2{
  filter: invert(26%) sepia(100%) saturate(1745%) hue-rotate(164deg) brightness(94%) contrast(102%);
}

.filter-cyan-3{
  filter: invert(44%) sepia(60%) saturate(4173%) hue-rotate(158deg) brightness(92%) contrast(101%);
}

.filter-cyan-4{
  filter: invert(56%) sepia(75%) saturate(4178%) hue-rotate(154deg) brightness(97%) contrast(101%);
}

.filter-cyan-5{
  filter: invert(61%) sepia(11%) saturate(7156%) hue-rotate(144deg) brightness(93%) contrast(101%);
}

.filter-cyan-6{
  filter: invert(71%) sepia(31%) saturate(1112%) hue-rotate(137deg) brightness(92%) contrast(84%);
}

.filter-cyan-7{
  filter: invert(68%) sepia(97%) saturate(317%) hue-rotate(142deg) brightness(93%) contrast(89%);
}

.filter-cyan-8{
  filter: invert(77%) sepia(70%) saturate(280%) hue-rotate(144deg) brightness(95%) contrast(92%);
}

/* cyan obscuro */

.filter-dark-cyan-1{
  filter: invert(20%) sepia(81%) saturate(593%) hue-rotate(124deg) brightness(95%) contrast(103%);
}

.filter-dark-cyan-2{
  filter: invert(27%) sepia(40%) saturate(1524%) hue-rotate(133deg) brightness(101%) contrast(101%);
}

.filter-dark-cyan-3{
  filter: invert(23%) sepia(91%) saturate(2409%) hue-rotate(160deg) brightness(86%) contrast(105%);
}

.filter-dark-cyan-4{
  filter: invert(48%) sepia(65%) saturate(5983%) hue-rotate(152deg) brightness(84%) contrast(101%);
}

.filter-dark-cyan-5{
  filter: invert(40%) sepia(71%) saturate(667%) hue-rotate(128deg) brightness(96%) contrast(101%);
}

.filter-dark-cyan-6{
  filter: invert(55%) sepia(49%) saturate(541%) hue-rotate(125deg) brightness(89%) contrast(99%);
}

.filter-dark-cyan-7{
  filter: invert(64%) sepia(29%) saturate(690%) hue-rotate(125deg) brightness(94%) contrast(89%);
}

.filter-dark-cyan-8{
  filter: invert(78%) sepia(34%) saturate(363%) hue-rotate(125deg) brightness(92%) contrast(85%);
}

/* verde */

.filter-green-1{
  filter: invert(29%) sepia(22%) saturate(1463%) hue-rotate(74deg) brightness(94%) contrast(95%);
}

.filter-green-2{
  filter: invert(42%) sepia(12%) saturate(2256%) hue-rotate(73deg) brightness(94%) contrast(96%);
}

.filter-green-3{
  filter: invert(56%) sepia(9%) saturate(2762%) hue-rotate(73deg) brightness(81%) contrast(91%);
}

.filter-green-4{
  filter: invert(57%) sepia(12%) saturate(3414%) hue-rotate(74deg) brightness(91%) contrast(59%);
}

.filter-green-5{
  filter: invert(58%) sepia(10%) saturate(2499%) hue-rotate(73deg) brightness(100%) contrast(84%);
}

.filter-green-6{
  filter: invert(70%) sepia(24%) saturate(814%) hue-rotate(73deg) brightness(91%) contrast(84%);
}

.filter-green-7{
  filter: invert(72%) sepia(7%) saturate(2001%) hue-rotate(73deg) brightness(99%) contrast(91%);
}

.filter-green-8{
  filter: invert(93%) sepia(6%) saturate(1843%) hue-rotate(62deg) brightness(96%) contrast(74%);
}

/* amarillo obscuro */

.filter-dark-yellow-1{
  filter: invert(39%) sepia(11%) saturate(4871%) hue-rotate(25deg) brightness(105%) contrast(82%);
}

.filter-dark-yellow-2{
  filter: invert(78%) sepia(8%) saturate(2980%) hue-rotate(19deg) brightness(73%) contrast(114%);
}

.filter-dark-yellow-3{
  filter: invert(69%) sepia(32%) saturate(935%) hue-rotate(22deg) brightness(95%) contrast(83%);
}

.filter-dark-yellow-4{
  filter: invert(81%) sepia(64%) saturate(507%) hue-rotate(10deg) brightness(90%) contrast(89%);
}

.filter-dark-yellow-5{
  filter: invert(95%) sepia(67%) saturate(952%) hue-rotate(6deg) brightness(92%) contrast(85%);
}

.filter-dark-yellow-6{
  filter: invert(94%) sepia(48%) saturate(769%) hue-rotate(6deg) brightness(94%) contrast(86%);
}

.filter-dark-yellow-7{
  filter: invert(81%) sepia(89%) saturate(253%) hue-rotate(8deg) brightness(96%) contrast(92%);
}

.filter-dark-yellow-8{
  filter: invert(89%) sepia(10%) saturate(1216%) hue-rotate(20deg) brightness(106%) contrast(90%);
}

/* naranja bajito */

.filter-light-orange-1{
  filter: invert(69%) sepia(60%) saturate(5077%) hue-rotate(353deg) brightness(101%) contrast(92%);
}

.filter-light-orange-2{
  filter: invert(78%) sepia(29%) saturate(2957%) hue-rotate(341deg) brightness(101%) contrast(95%);
}

.filter-light-orange-3{
  filter: invert(73%) sepia(59%) saturate(664%) hue-rotate(340deg) brightness(108%) contrast(97%);
}

.filter-light-orange-4{
  filter: invert(90%) sepia(98%) saturate(2862%) hue-rotate(326deg) brightness(103%) contrast(98%);
}

.filter-light-orange-5{
  filter: invert(84%) sepia(96%) saturate(1827%) hue-rotate(324deg) brightness(111%) contrast(104%);
}

.filter-light-orange-6{
  filter: invert(81%) sepia(76%) saturate(378%) hue-rotate(347deg) brightness(103%) contrast(106%);
}

.filter-light-orange-7{
  filter: invert(93%) sepia(31%) saturate(835%) hue-rotate(335deg) brightness(102%) contrast(102%);
}

.filter-light-orange-8{
  filter: invert(91%) sepia(64%) saturate(376%) hue-rotate(336deg) brightness(101%) contrast(101%);
}

/* naranja */

.filter-orange-1{
  filter: invert(38%) sepia(100%) saturate(3917%) hue-rotate(12deg) brightness(100%) contrast(101%);
}

.filter-orange-2{
  filter: invert(45%) sepia(72%) saturate(2471%) hue-rotate(5deg) brightness(97%) contrast(102%);
}

.filter-orange-3{
  filter: invert(45%) sepia(90%) saturate(1182%) hue-rotate(4deg) brightness(98%) contrast(105%);
}

.filter-orange-4{
  filter: invert(67%) sepia(30%) saturate(6389%) hue-rotate(2deg) brightness(98%) contrast(104%);
}

.filter-orange-5{
  filter: invert(53%) sepia(83%) saturate(988%) hue-rotate(1deg) brightness(103%) contrast(106%);
}

.filter-orange-6{
  filter: invert(60%) sepia(99%) saturate(412%) hue-rotate(343deg) brightness(103%) contrast(103%);
}

.filter-orange-7{
  filter: invert(90%) sepia(18%) saturate(2186%) hue-rotate(320deg) brightness(101%) contrast(100%);
}

.filter-orange-8{
  filter: invert(79%) sepia(96%) saturate(380%) hue-rotate(313deg) brightness(103%) contrast(102%);
}

/* Cafe */

.filter-brown-1{
  filter: invert(10%) sepia(11%) saturate(2883%) hue-rotate(321deg) brightness(94%) contrast(82%);
}

.filter-brown-2{
  filter: invert(21%) sepia(6%) saturate(3045%) hue-rotate(323deg) brightness(90%) contrast(89%);
}

.filter-brown-3{
  filter: invert(22%) sepia(13%) saturate(1475%) hue-rotate(328deg) brightness(101%) contrast(84%);
}

.filter-brown-4{
  filter: invert(28%) sepia(46%) saturate(363%) hue-rotate(329deg) brightness(96%) contrast(88%);
}

.filter-brown-5{
  filter: invert(37%) sepia(14%) saturate(989%) hue-rotate(330deg) brightness(94%) contrast(97%);
}

.filter-brown-6{
  filter: invert(50%) sepia(16%) saturate(676%) hue-rotate(330deg) brightness(86%) contrast(82%);
}

.filter-brown-7{
  filter: invert(57%) sepia(17%) saturate(368%) hue-rotate(330deg) brightness(94%) contrast(90%);
}

.filter-brown-8{
  filter: invert(76%) sepia(14%) saturate(278%) hue-rotate(329deg) brightness(91%) contrast(84%);
}

/* Negro */

.filter-black-1{
  filter: invert(6%) sepia(33%) saturate(0%) hue-rotate(279deg) brightness(101%) contrast(86%);
}

.filter-black-2{
  filter: invert(21%) sepia(96%) saturate(0%) hue-rotate(206deg) brightness(91%) contrast(88%);
}

.filter-black-3{
  filter: invert(38%) sepia(0%) saturate(56%) hue-rotate(180deg) brightness(101%) contrast(100%);
}

.filter-black-4{
  filter: invert(47%) sepia(0%) saturate(0%) hue-rotate(135deg) brightness(95%) contrast(83%);
}

.filter-black-5{
  filter: invert(78%) sepia(0%) saturate(1015%) hue-rotate(136deg) brightness(82%) contrast(87%);
}

.filter-black-6{
  filter: invert(93%) sepia(0%) saturate(17%) hue-rotate(134deg) brightness(83%) contrast(88%);
}

.filter-black-7{
  filter: invert(100%) sepia(2%) saturate(3479%) hue-rotate(188deg) brightness(120%) contrast(76%);
}

.filter-black-8{
  filter: invert(99%) sepia(1%) saturate(713%) hue-rotate(218deg) brightness(112%) contrast(87%);
}

/* Gris obscuro */

.filter-dark-gray-1{
  filter: invert(20%) sepia(19%) saturate(471%) hue-rotate(155deg) brightness(89%) contrast(100%);
}

.filter-dark-gray-2{
  filter: invert(28%) sepia(10%) saturate(912%) hue-rotate(155deg) brightness(89%) contrast(95%);
}

.filter-dark-gray-3{
  filter: invert(31%) sepia(31%) saturate(366%) hue-rotate(155deg) brightness(92%) contrast(85%);
}

.filter-dark-gray-4{
  filter: invert(41%) sepia(22%) saturate(419%) hue-rotate(154deg) brightness(96%) contrast(94%);
}

.filter-dark-gray-5{
  filter: invert(47%) sepia(23%) saturate(394%) hue-rotate(155deg) brightness(96%) contrast(95%);
}

.filter-dark-gray-6{
  filter: invert(57%) sepia(17%) saturate(403%) hue-rotate(155deg) brightness(94%) contrast(89%);
}

.filter-dark-gray-7{
  filter: invert(60%) sepia(16%) saturate(300%) hue-rotate(155deg) brightness(103%) contrast(89%);
}

.center-screen-load {
  margin-top: 20%;
}

.filter-dark-gray-8{
  filter: invert(85%) sepia(11%) saturate(254%) hue-rotate(156deg) brightness(88%) contrast(88%);
}

.text-body-color-discreet {
  color: #757575;
}

.text-title-color-discreet {
  color: #616161;
}

.title-dark {
  color: #424242;
}

.title-light {
  color: #F5F5F5;
}

.text-gray-card {
  color: gray !important;
}
.mat-option{
  margin-top : 5px;
  margin-bottom: 5px;
}

.border-cell {
  border-radius: 10px;
}

.purple {
  background-color: #A75DE8;
  border-color: #A75DE8;
  color: white;
}

.bg-dark-input  {
  background-color: #343a40;
}

.bg-dark-input:focus {
  background-color: #343a40;
}

.bg-main-purple:hover {
  background-color: #9553ce;
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.animation {
  transition: all 0.3s ease-in-out;
}

.img-sign {
  width: 16px;
  height: 14px;
  margin-top: -1%;
  padding: 0;
}
