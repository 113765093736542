// Custom Theming for Angular Material
@use 'node_modules/@angular/material' as mat;
@import './assets/scss/themes.scss';
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

.myPanelClassDark{
    margin-top: 13% !important;
    margin-left: 14% !important;
    background-color: #343a40 !important;
}

.myPanelClassLight{
    margin-top: 13% !important;
    margin-left: 14% !important;
    background-color: white !important;
}

.mat-option-pseudo-checkbox.mat-pseudo-checkbox-checked {
    background-color: #A75DE8;
}

.dark-mode .mat-select-arrow {
    color: white;
}

.dark-mode .mat-select-trigger {
    background-color: none;
}

.dark-mode .mat-select-value-text {
    color: white;
}

.light-mode .mat-select-arrow {
    color: #5d6368;
}

.light-mode-option .mat-select-arrow {
    color: white;
}

.light-mode-option .mat-select-value-text {
    color: white;
}

.mat-select-panel-wrap .mat-select-panel{
    min-width: calc(99%) !important;
    transform: translateX(4px) !important;
}

.cdk-global-overlay-wrapper, 
.cdk-overlay-container, 
.cdk-overlay-connected-position-bounding-box {
    z-index: 9999!important;
}

.select-dialog {
    .mat-select-trigger{
        border: none;
        outline: none;
        color: inherit;
        text-decoration: none;
        border-radius: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 10px;
    }
    
    .mat-select-arrow {
        color: white;
    }
}

.no-arrow{
    .mat-select-arrow {
        border: none;
    }
}
